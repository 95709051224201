import React, { Suspense } from "react";

import { useSelector } from "react-redux";
import AddFeatureModal from "../../../../components/AddFeature/AddFeatureModal";
import AddTaskModal from "main/components/AddTask/AddTaskModal";

const TasksApprovedReject = React.lazy(() =>
  import("../../../../components/TasksApprovedReject/TasksApprovedReject")
);

const TasksApprovedRejectView = React.lazy(() =>
  import(
    "../../../../components/TasksApprovedRejectView/TasksApprovedRejectView"
  )
);
const TasksRejectEditorModal = React.lazy(() =>
  import("../../../../components/TasksRejectEditorModal")
);

const TasksApproveEditorModal = React.lazy(() =>
  import("../../../../components/TasksApproveEditorModal")
);

const AddInstructionalImage = React.lazy(() =>
  import("../../../../components/AddInstructionalImage/AddInstructionalImage")
);

const AddWholeTaskImages = React.lazy(() =>
  import("../../../../components/AddWholeTaskImages")
);

const TestRecommendationTasksModal = React.lazy(() =>
  import(
    "../../../../components/TestRecommendationTasksModal/TestRecommendationTasksModal"
  )
);
const ConfirmationModal = React.lazy(() =>
  import("../../../../components/ConfirmationModal/ConfirmationModal")
);

const FullSizeImageModal = React.lazy(() =>
  import("../../../../components/FullSizeImageModal")
);

const ObservationModals = ({
  selectedSubmittedImage,
  role,
  userInfo,
  selectedSubmittedImageNumber,
  selectedInstructImageNumber,
  showselectedSubmittedImageModal,
  onHidesetselectedSubmittedImageModal,
  setrejectedModal,
  refreshlinkTasks,
  setrefreshlinkTasks,
  setnotRootLevelRefresh,
  onHideselectedSubmittedImageModalView,
  showselectedSubmittedImageModalView,
  showselectedInstructImageModalView,
  onHideselectedInstructImageModalView,
  setBitImage,
  setrejectedModalAddnote,
  setselectedSubmittedImageModal,
  showrejectedModal,
  onHiderejectedModal,
  showrejectedModalAddnote,
  onHiderejectedModalAddnote,
  BitImage,
  nextImage,
  prevImage,
  showaddInstructionalModal,
  onHideaddInstructionalModal,
  selectedTask,
  insp_id,
  openAddFeatureModal,
  setOpenAddFeatureModal,
  selectedRoomId,
  onHideAddWholeTaskImagesModal,
  showAddWholeTaskImagesModal,
  selectedSubmittedImageInstructional,
  testRecommendationModal,
  settestRecommendationModal,
  changeImage,
  confirmModal,
  setConfirmModal,
  showApprovedEditModal,
  setShowApprovedEditModal,
  confirmTestModal,
  setConfirmTestModal,
  conditionalView,
  setConditionalViewer,
  inspectionDetails,
  updateImageFullScreenModal,
  imageFullScreenModal,
  openAddTaskModal,
  setOpenAddTaskModal,
}) => {
  const checkDisable = useSelector((state) => state.inspectionDisable.disable);
  const checkImgData = (type) => {
    if (type === "next") {
      if (conditionalView === "instructional") {
        return !changeImage?.totalInstImgaes.some(
          (el) => changeImage.selectedSubmittedImageIndex < el
        );
      } else {
        return !changeImage?.totalImages.some(
          (el) => changeImage.selectedSubmittedImageIndex < el
        );
      }
    } else {
      if (conditionalView === "instructional") {
        return !changeImage?.totalInstImgaes.some(
          (el) => changeImage.selectedSubmittedImageIndex > el
        );
      } else {
        return !changeImage?.totalImages.some(
          (el) => changeImage.selectedSubmittedImageIndex > el
        );
      }
    }
  };
  return (
    <React.Fragment>
      <Suspense fallback={null}>
        <TasksApprovedReject
          changeNextImage={checkImgData("next")}
          initialloader={true}
          changePrevImage={checkImgData("prev")}
          changeImage={changeImage}
          selectedSubmittedImage={selectedSubmittedImage}
          role={role}
          nextImage={nextImage}
          prevImage={prevImage}
          selectedSubmittedImageNumber={selectedSubmittedImageNumber}
          show={showselectedSubmittedImageModal}
          onHide={() => onHidesetselectedSubmittedImageModal()}
          setrejectedModal={setrejectedModal}
          refreshlinkTasks={refreshlinkTasks}
          settestRecommendationModal={settestRecommendationModal}
          testRecommendationModal={testRecommendationModal}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setConfirmModal={setConfirmModal}
          conditionalViewer={conditionalView}
          setConditionalViewer={setConditionalViewer}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TasksApprovedRejectView
          selectedSubmittedImage={selectedSubmittedImageInstructional}
          instructional={true}
          selectedInstructImageNumber={selectedInstructImageNumber}
          show={showselectedInstructImageModalView}
          onHide={() => onHideselectedInstructImageModalView()}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TasksRejectEditorModal
          selectedSubmittedImage={selectedSubmittedImage}
          role={role}
          selectedSubmittedImageNumber={selectedSubmittedImageNumber}
          setBitImage={setBitImage}
          setrejectedModalAddnote={setrejectedModalAddnote}
          setselectedSubmittedImageModal={setselectedSubmittedImageModal}
          show={showrejectedModal}
          conditionalViewer={conditionalView}
          onHide={() => onHiderejectedModal()}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TasksApproveEditorModal
          selectedSubmittedImage={selectedSubmittedImage}
          role={role}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
          setrefreshlinkTasks={setrefreshlinkTasks}
          selectedSubmittedImageNumber={selectedSubmittedImageNumber}
          show={showApprovedEditModal}
          onHide={() => setShowApprovedEditModal(false)}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TasksApprovedReject
          changeNextImage={
            changeImage.selectedSubmittedImageIndex >=
            changeImage.selectedTask?.images?.length - 1
          }
          initialloader={true}
          changePrevImage={changeImage.selectedSubmittedImageIndex === 0}
          changeImage={changeImage}
          role={role}
          selectedSubmittedImage={selectedSubmittedImage}
          selectedSubmittedImageNumber={selectedSubmittedImageNumber}
          imageEdited={BitImage}
          nextImage={nextImage}
          prevImage={prevImage}
          noteModal={true}
          show={showrejectedModalAddnote}
          onHide={() => onHiderejectedModalAddnote()}
          refreshlinkTasks={refreshlinkTasks}
          conditionalViewer={conditionalView}
          setConditionalViewer={setConditionalViewer}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
        />
      </Suspense>

      <Suspense fallback={null}>
        <AddInstructionalImage
          selectedTask={selectedTask}
          role={role}
          insp_id={insp_id}
          refreshlinkTasks={refreshlinkTasks}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
          show={showaddInstructionalModal}
          onHide={() => onHideaddInstructionalModal()}
        />
      </Suspense>

      {/* /** ====================== Add Feature ========================== */}
      <AddFeatureModal
        role={role}
        selectedRoomId={selectedRoomId}
        insp_id={insp_id}
        openAddFeatureModal={openAddFeatureModal}
        setOpenAddFeatureModal={setOpenAddFeatureModal}
        setrefreshlinkTasks={setrefreshlinkTasks}
        setnotRootLevelRefresh={setnotRootLevelRefresh}
      />

      {/* /** ====================== Add Task ========================== */}
      <AddTaskModal
        role={role}
        selectedRoomId={selectedRoomId}
        observationId={selectedTask}
        insp_id={insp_id}
        openAddTaskModal={openAddTaskModal}
        setOpenAddTaskModal={setOpenAddTaskModal}
        setrefreshlinkTasks={setrefreshlinkTasks}
        setnotRootLevelRefresh={setnotRootLevelRefresh}
      />

      <Suspense fallback={null}>
        <AddWholeTaskImages
          selectedTask={selectedTask}
          role={role}
          selectedRoomId={selectedRoomId}
          insp_id={insp_id}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
          show={showAddWholeTaskImagesModal}
          onHide={() => onHideAddWholeTaskImagesModal(false)}
        />
      </Suspense>

      <Suspense fallback={null}>
        <ConfirmationModal
          show={confirmModal}
          onHide={() => setConfirmModal(false)}
          hideIcon={true}
          imgSrc={require("../../../../../assets/icons/delete.png").default}
          helperText="Do you want to recommend a test?"
          onSave={() => {
            settestRecommendationModal(true);
          }}
        />
      </Suspense>

      <Suspense fallback={null}>
        <ConfirmationModal
          show={confirmTestModal}
          disable={checkDisable}
          hideIcon={true}
          onHide={() => setConfirmTestModal(false)}
          helperText="Do you want to recommend a test?"
          headingText="No Test Recommended"
          onSave={() => {
            settestRecommendationModal(true);
          }}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TestRecommendationTasksModal
          role={role}
          modalTitle="Test Recommendation"
          selectedSubmittedImage={selectedSubmittedImage}
          setrefreshlinkTasks={setrefreshlinkTasks}
          submit={false}
          multiList={[]}
          multivalue={[]}
          insp_id={insp_id}
          show={testRecommendationModal}
          onSave={() => {
            setShowApprovedEditModal(true);
          }}
          onHide={() => {
            settestRecommendationModal(false);
          }}
        />
      </Suspense>
      <Suspense fallback={null}>
        <FullSizeImageModal
          selectedSubmittedImage={selectedSubmittedImage}
          onHide={updateImageFullScreenModal}
          show={imageFullScreenModal}
        />
      </Suspense>
    </React.Fragment>
  );
};

export default ObservationModals;
