import React, { useState, Suspense } from "react";
import {
  GET_FEATURE_DETAILS_RESET,
  GET_FEATURE_LIST_RESET,
} from "../../../store/types/select_feature/selectFeatureTypes";
import { useDispatch } from "react-redux";
import { WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET, WHOLE_INSTRUCTIONAL_TASK_RESET } from "store/types/select_instructional_task/selectInstructionalTaskTypes";

const SelectFeature = React.lazy(() => import("./SelectFeature/SelectFeature"));
const SelectTask = React.lazy(() => import("./SelectTask/SelectTask"));
const SubmitWholeFeature = React.lazy(() =>
  import("./SubmitWholeFeature/SubmitWholeFeature")
);

const AddFeatureModal = ({
  role,
  selectedRoomId,
  insp_id,
  openAddFeatureModal,
  setOpenAddFeatureModal,
  setrefreshlinkTasks,
  setnotRootLevelRefresh,
}) => {
  const dispatch = useDispatch();
  const [openSubmitWholeFeatureModal, setOpenSubmitWholeFeatureModal] =
    useState(false);
  const [openSelectTaskModal, setOpenSelectTaskModal] = useState(false);

  const [searchFeature, setSearchFeature] = useState("");
  const [searchTask, setSearchTask] = useState("");
  const [selectedFeatId, setSelectedFeatId] = useState("");

  const resetAllFields = () => {
    setSearchFeature("");
    setSearchTask("");
    setSelectedFeatId("");
    dispatch({
      type: GET_FEATURE_LIST_RESET,
    });
    dispatch({
      type: GET_FEATURE_DETAILS_RESET,
    });
    dispatch({
      type: WHOLE_INSTRUCTIONAL_TASK_RESET,
    });
    dispatch({
      type: WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET,
    });
  };

  return (
    <>
      <Suspense fallback={null}>
        <SelectFeature
          role={role}
          selectedRoomId={selectedRoomId}
          insp_id={insp_id}
          openAddFeatureModal={openAddFeatureModal}
          onHide={() => setOpenAddFeatureModal(false)}
          setOpenSubmitWholeFeatureModal={setOpenSubmitWholeFeatureModal}
          searchFeature={searchFeature}
          setSearchFeature={setSearchFeature}
          selectedFeatId={selectedFeatId}
          setSelectedFeatId={setSelectedFeatId}
          resetAllFields={resetAllFields}
        />
      </Suspense>
      <Suspense fallback={null}>
        <SubmitWholeFeature
          role={role}
          selectedRoomId={selectedRoomId}
          insp_id={insp_id}
          show={openSubmitWholeFeatureModal}
          setOpenAddFeatureModal={setOpenAddFeatureModal}
          setOpenSelectTaskModal={setOpenSelectTaskModal}
          onHide={() => setOpenSubmitWholeFeatureModal(false)}
          resetAllFields={resetAllFields}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
        />
      </Suspense>
      <Suspense fallback={null}>
        <SelectTask
          role={role}
          selectedRoomId={selectedRoomId}
          insp_id={insp_id}
          searchTask={searchTask}
          setSearchTask={setSearchTask}
          show={openSelectTaskModal}
          onHide={() => setOpenSelectTaskModal(false)}
          resetAllFields={resetAllFields}
          setOpenSubmitWholeFeatureModal={setOpenSubmitWholeFeatureModal}
        />
      </Suspense>
    </>
  );
};

export default AddFeatureModal;
