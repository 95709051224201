import React, { Suspense, useState } from "react";
import { useDispatch } from "react-redux";
import {
  WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET,
  WHOLE_INSTRUCTIONAL_TASK_RESET,
} from "store/types/select_instructional_task/selectInstructionalTaskTypes";
import {
  GET_FEATURE_DETAILS_RESET,
  GET_FEATURE_LIST_RESET,
} from "../../../store/types/select_feature/selectFeatureTypes";

const SelectTask = React.lazy(() => import("./SelectTask/SelectTask"));
const SubmitWholeFeature = React.lazy(() =>
  import("./SubmitWholeFeature/SubmitWholeFeature")
);

const AddTaskModal = ({
  role,
  selectedRoomId,
  observationId,

  openAddTaskModal,
  setOpenAddTaskModal,
  setrefreshlinkTasks,
  setnotRootLevelRefresh,
}) => {
  const dispatch = useDispatch();
  const [openSubmitWholeFeatureModal, setOpenSubmitWholeFeatureModal] =
    useState(false);
  const [openSelectTaskModal, setOpenSelectTaskModal] = useState(false);

  const [searchTask, setSearchTask] = useState("");

  const resetAllFields = () => {
    setSearchTask("");
    dispatch({
      type: GET_FEATURE_LIST_RESET,
    });
    dispatch({
      type: GET_FEATURE_DETAILS_RESET,
    });
    dispatch({
      type: WHOLE_INSTRUCTIONAL_TASK_RESET,
    });
    dispatch({
      type: WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET,
    });
  };

  return (
    <>
      <Suspense fallback={null}>
        <SubmitWholeFeature
          role={role}
          selectedRoomId={selectedRoomId}
          observationId={observationId}
          show={openSubmitWholeFeatureModal}
          setOpenAddFeatureModal={setOpenAddTaskModal}
          setOpenSelectTaskModal={setOpenSelectTaskModal}
          onHide={() => setOpenSubmitWholeFeatureModal(false)}
          resetAllFields={resetAllFields}
          setrefreshlinkTasks={setrefreshlinkTasks}
          setnotRootLevelRefresh={setnotRootLevelRefresh}
        />
      </Suspense>
      <Suspense fallback={null}>
        <SelectTask
          role={role}
          selectedRoomId={selectedRoomId}
          searchTask={searchTask}
          setSearchTask={setSearchTask}
          show={openAddTaskModal}
          onHide={() => setOpenAddTaskModal(false)}
          resetAllFields={resetAllFields}
          setOpenSubmitWholeFeatureModal={setOpenSubmitWholeFeatureModal}
        />
      </Suspense>
    </>
  );
};
export default AddTaskModal;
