export const SELECT_INSTRUCTIONAL_TASK_REQUEST =
  "SELECT_INSTRUCTIONAL_TASK_REQUEST";
export const SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS =
  "SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS";
export const SELECT_ALL_INSTRUCTIONAL_TASK_ADD_SUCCESS =
  "SELECT_ALL_INSTRUCTIONAL_TASK_ADD_SUCCESS";
export const SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS =
  "SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS";

export const REMOVE_INSTRUCTIONAL_TASK_FROM_ALL_TASK_LIST =
  "REMOVE_INSTRUCTIONAL_TASK_FROM_ALL_TASK_LIST";

export const SEARCH_INSTRUCTIONAL_TASK_RESET =
  "SEARCH_INSTRUCTIONAL_TASK_RESET";

export const TEMP_INSTRUCTIONAL_TASK_RESET = "TEMP_INSTRUCTIONAL_TASK_RESET";
export const COPY_TEMP_INSTRUCTIONAL_TASK_TO_FINAL_SELECTION =
  "COPY_TEMP_INSTRUCTIONAL_TASK_TO_FINAL_SELECTION";
export const COPY_INSTRUCTIONAL_TASK_TO_TEMP_SELECTION =
  "COPY_INSTRUCTIONAL_TASK_TO_TEMP_SELECTION";

export const GET_INSTRUCTIONAL_TASK_REQUEST = "GET_INSTRUCTIONAL_TASK_REQUEST";
export const GET_INSTRUCTIONAL_TASK_SUCCESS = "GET_INSTRUCTIONAL_TASK_SUCCESS";

export const WHOLE_INSTRUCTIONAL_TASK_FAIL = "WHOLE_INSTRUCTIONAL_TASK_FAIL";
export const WHOLE_INSTRUCTIONAL_TASK_RESET = "WHOLE_INSTRUCTIONAL_TASK_RESET";

export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST =
  "SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS =
  "SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS";
export const SELECT_ALL_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS =
  "SELECT_ALL_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS =
  "SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS";

export const REMOVE_INSTRUCTIONAL_CUSTOM_TASK_FROM_ALL_TASK_LIST =
  "REMOVE_INSTRUCTIONAL_CUSTOM_TASK_FROM_ALL_TASK_LIST";

export const TEMP_INSTRUCTIONAL_CUSTOM_TASK_RESET =
  "TEMP_INSTRUCTIONAL_CUSTOM_TASK_RESET";
export const COPY_TEMP_INSTRUCTIONAL_CUSTOM_TASK_TO_FINAL_SELECTION =
  "COPY_TEMP_INSTRUCTIONAL_CUSTOM_TASK_TO_FINAL_SELECTION";
export const COPY_INSTRUCTIONAL_CUSTOM_TASK_TO_TEMP_SELECTION =
  "COPY_INSTRUCTIONAL_CUSTOM_TASK_TO_TEMP_SELECTION";

export const SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK =
  "SELECTION_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK";

export const UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_REQUEST =
  "UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_REQUEST";
export const UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_SUCCESS =
  "UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_SUCCESS";
export const UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_FAIL =
  "UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_FAIL";

export const WHOLE_INSTRUCTIONAL_CUSTOM_TASK_FAIL =
  "WHOLE_INSTRUCTIONAL_CUSTOM_TASK_FAIL";
export const WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET =
  "WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET";
