import {
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST,
  SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS,
  WHOLE_INSTRUCTIONAL_CUSTOM_TASK_FAIL,
  WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET,
  SELECT_INSTRUCTIONAL_TASK_REQUEST,
  SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS,
  SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS,
  WHOLE_INSTRUCTIONAL_TASK_RESET,
  GET_INSTRUCTIONAL_TASK_SUCCESS,
  GET_INSTRUCTIONAL_TASK_REQUEST,
  WHOLE_INSTRUCTIONAL_TASK_FAIL,
  UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_REQUEST,
  UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_SUCCESS,
  UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_FAIL,
  TEMP_INSTRUCTIONAL_TASK_RESET,
  COPY_TEMP_INSTRUCTIONAL_TASK_TO_FINAL_SELECTION,
  COPY_INSTRUCTIONAL_TASK_TO_TEMP_SELECTION,
  SEARCH_INSTRUCTIONAL_TASK_RESET,
  TEMP_INSTRUCTIONAL_CUSTOM_TASK_RESET,
  COPY_INSTRUCTIONAL_CUSTOM_TASK_TO_TEMP_SELECTION,
  COPY_TEMP_INSTRUCTIONAL_CUSTOM_TASK_TO_FINAL_SELECTION,
  REMOVE_INSTRUCTIONAL_TASK_FROM_ALL_TASK_LIST,
  REMOVE_INSTRUCTIONAL_CUSTOM_TASK_FROM_ALL_TASK_LIST,
  SELECT_ALL_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS,
  SELECT_ALL_INSTRUCTIONAL_TASK_ADD_SUCCESS,
} from "../../types/select_instructional_task/selectInstructionalTaskTypes";

const storeTaskInitialState = {
  loading: false,
  storedTaskList: { data: [] },
  tempSelectedStoreTaskList: [],
  selectedStoreTaskList: [],
  error: "",
};

export const instructionalStoreTaskSelectionReducers = (
  state = storeTaskInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INSTRUCTIONAL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECT_INSTRUCTIONAL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INSTRUCTIONAL_TASK_SUCCESS:
      const oldList = state.storedTaskList.data;
      const combineList = [...oldList, ...payload.data];
      return {
        ...state,
        loading: false,
        storedTaskList: {
          ...payload,
          data: combineList,
        },
        error: "",
      };

    case SELECT_ALL_INSTRUCTIONAL_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        tempSelectedStoreTaskList: [...payload],
        error: "",
      };

    case SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        tempSelectedStoreTaskList: [
          ...state.tempSelectedStoreTaskList,
          payload,
        ],
        error: "",
      };

    case SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS:
      const removeStoreTask = state.tempSelectedStoreTaskList.filter(
        (task) => task?.thumbnail !== payload?.thumbnail
      );
      return {
        ...state,
        loading: false,
        tempSelectedStoreTaskList: removeStoreTask,
        error: "",
      };

    case REMOVE_INSTRUCTIONAL_TASK_FROM_ALL_TASK_LIST:
      const tasks = state.selectedStoreTaskList.filter(
        (task) => task?.thumbnail !== payload?.thumbnail
      );
      return {
        ...state,
        loading: false,
        selectedStoreTaskList: tasks,
        error: "",
      };

    case TEMP_INSTRUCTIONAL_TASK_RESET:
      return {
        ...state,
        loading: false,
        tempSelectedStoreTaskList: [],
      };

    case COPY_TEMP_INSTRUCTIONAL_TASK_TO_FINAL_SELECTION:
      return {
        ...state,
        loading: false,
        selectedStoreTaskList: JSON.parse(
          JSON.stringify(state.tempSelectedStoreTaskList)
        ),
      };

    case COPY_INSTRUCTIONAL_TASK_TO_TEMP_SELECTION:
      return {
        ...state,
        loading: false,
        tempSelectedStoreTaskList: JSON.parse(
          JSON.stringify(state.selectedStoreTaskList)
        ),
      };

    case WHOLE_INSTRUCTIONAL_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        storedTaskList: { data: [] },
        tempSelectedStoreTaskList: [],
        selectedStoreTaskList: [],
      };

    case SEARCH_INSTRUCTIONAL_TASK_RESET:
      return {
        ...state,
        loading: false,
        error: "",
        storedTaskList: { data: [] },
      };

    case WHOLE_INSTRUCTIONAL_TASK_RESET:
      return {
        loading: false,
        error: "",
        storedTaskList: { data: [] },
        tempSelectedStoreTaskList: [],
        selectedStoreTaskList: [],
      };

    default:
      return state;
  }
};

const customTaskInitialState = {
  loading: false,
  customTaskList: [],
  tempSelectedCustomTaskList: [],
  selectedCustomTaskList: [],
  taskConvertURL: [],
  error: "",
};

export const instructionalCustomTaskSelectionReducers = (
  state = customTaskInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SELECT_ALL_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        tempSelectedCustomTaskList: [...payload],
        error: "",
      };
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        tempSelectedCustomTaskList: [
          ...state.tempSelectedCustomTaskList,
          payload,
        ],
        error: "",
      };
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS:
      const removeCustomTask = state.tempSelectedCustomTaskList.filter(
        (task) => task?.thumbnail !== payload?.thumbnail
      );
      return {
        ...state,
        loading: false,
        tempSelectedCustomTaskList: removeCustomTask,
        error: "",
      };
    case REMOVE_INSTRUCTIONAL_CUSTOM_TASK_FROM_ALL_TASK_LIST:
      const tasks = state.selectedCustomTaskList.filter(
        (task) => task?.image_name !== payload?.image_name
      );
      return {
        ...state,
        loading: false,
        selectedCustomTaskList: tasks,
        error: "",
      };
    case SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK:
      return {
        ...state,
        loading: false,
        customTaskList: [...state.customTaskList, payload],
        tempSelectedCustomTaskList: [
          ...state.tempSelectedCustomTaskList,
          payload,
        ],
        error: "",
      };
    case TEMP_INSTRUCTIONAL_CUSTOM_TASK_RESET:
      return {
        ...state,
        loading: false,
        tempSelectedCustomTaskList: [],
      };

    case COPY_TEMP_INSTRUCTIONAL_CUSTOM_TASK_TO_FINAL_SELECTION:
      return {
        ...state,
        loading: false,
        selectedCustomTaskList: JSON.parse(
          JSON.stringify(state.tempSelectedCustomTaskList)
        ),
      };

    case COPY_INSTRUCTIONAL_CUSTOM_TASK_TO_TEMP_SELECTION:
      return {
        ...state,
        loading: false,
        tempSelectedCustomTaskList: JSON.parse(
          JSON.stringify(state.selectedCustomTaskList)
        ),
      };

    case UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        taskConvertURL: payload,
      };
    case WHOLE_INSTRUCTIONAL_CUSTOM_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case UPLOAD_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case WHOLE_INSTRUCTIONAL_CUSTOM_TASK_RESET:
      return {
        loading: false,
        error: "",
        customTaskList: [],
        tempSelectedCustomTaskList: [],
        selectedCustomTaskList: [],
        taskConvertURL: [],
      };

    default:
      return state;
  }
};
